import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IWidget } from '../../model/widget.model';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  private widgetDesign: BehaviorSubject<any> = new BehaviorSubject(null);
  currentWidgetDesign = this.widgetDesign.asObservable();

  private widgetListSource: BehaviorSubject<number> = new BehaviorSubject(0);
  currentWidget = this.widgetListSource.asObservable();
  
  constructor(private mainService:MainService, private router: Router) { }

  changeWidget(widget: number){
    this.widgetListSource.next(widget);
  }

  changeWidgetDesign(item){
    this.widgetDesign.next(item);
  }

  getWidgetConfiguration(widget:IWidget, action_type_id:number): Promise<any[]> {
		let data: any = {"widget":widget, "action_type_id":action_type_id};
		return this.mainService
		.postService('api/Admin/com.svc/admin/com/widget/set-get/', data)
		.then(replay => {
			if (Number(replay['replyErrorId']) < 0){
				//this.router.navigate(['error', '400']);
				throw("400");
			}else {
				return JSON.parse(replay["ReplyData"]);
			}

		   
		})
		.catch(err => {
			//this.router.navigate(['/error/', '400']);
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}
}
