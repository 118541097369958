import { Component, OnInit, ViewEncapsulation, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '../../services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../services/session.service';
import * as uuid from 'uuid';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { ComService } from '../../services/com.service';
import { widgetModuleFabAnimations } from '../widget/widget-module/widget-module.animations';
import { SocketService } from 'src/app/services/socket.service';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-chat-welcome',
  templateUrl: './chat-welcome.component.html',
  styleUrls: ['./chat-welcome.component.css'],
  animations: widgetModuleFabAnimations
})
export class ChatWelcomeComponent implements OnInit {
  @Output() public activeSubScreen: EventEmitter<string> = new EventEmitter();
  @Output() public fabTogglerState: EventEmitter<string> = new EventEmitter();
  @Input() public isWidget: boolean;
  @ViewChild('sidenav', {static : false}) userDetailsSideNav;
 

  public isLoading:boolean = true;
  public message:string="";
  public mode = new FormControl('over');
  public ws_config:any = {};
  public ws_config_form = [];
  public cm_ws_avatar_agent_image = './assets/images/300_10.jpg';
  private session_id:string = '';
  private widget_guid:string = '';
  private uid:string = '';
  public is_chat_widget:boolean = false;
  public form;
  public hideBackBtn        :boolean = true;
  public chat_form_object:any[] = [];
  public session:any;
  private person:any;
  private person_id:number = undefined;
  loader: boolean;
  public showConnectBtn:boolean = true;
  constructor(private router: Router,
    private chatService: ChatService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private sessionService: SessionService,
    private comService: ComService,
    private layoutConfigService: LayoutConfigService) { }

  ngOnInit() {
    // connect to socket
    // console.log('socket connection');
    // this.socketService.socketConnect();
    this.uid = this.sessionService.getUID();
    //this.person = this.sessionService.getUserSession();

    this.setUpSessionVisitorOnInit();

    if (this.isWidget){
      this.widget_guid = this.route.snapshot.paramMap.get("id");
    } 

   

    // if (this.person.length === 0){
    //   this.findPersonBySession(this.uid);
    // }
    
		//this.loader = this.layoutConfigService.getConfig('loader.enabled');
    this.getSessionIdFromCache();
    
    // check if session id guid comming from URL or from cache
    if(this.checkIfSessionOpen()){
      this.isLoading = true;

      // check if session id is state 2 or 3
      this.getWelcomeScreen();
      this.goToChatLink(this.session_id);
    } else {
      this.checkAndCreateWidgetChat();
    }


    // if(this.session_id !== undefined && this.session_id !== null && this.session_id !== '' && this.session_id !== 'null'){
    //   this.setUpSessionOnInit();
    // } else {
    //   this.session_id = this.sessionService.getSessionId();
    //   if(this.session_id !== undefined && this.session_id !== null && this.session_id !== '' && this.session_id !== 'null'){
    //     this.setUpSessionOnInit();
    //     this.checkAndCreateWidgetChat();
    //   } else {
    //     this.checkAndCreateWidgetChat();
    //   };
    // };   
  }

  getSessionIdFromCache(){
    this.session_id = this.route.snapshot.paramMap.get("session_id");
    if (!this.session_id){
      this.session_id = this.sessionService.getSessionId();
    }
  };

  getPersonDetails(){
    
  }

  checkIfSessionOpen(){
    if(this.session_id !== undefined && this.session_id !== null && this.session_id !== '' && this.session_id !== 'null'){
      return true;
    }
    else{
      return false;
    };
  }

  // set up chat configuration => session id exists
  getWelcomeScreen(){
    console.log('setUpSessionOnInit fn.' + this.session_id);
    this.getChatWelcomeConfiguration(this.session_id);   
  }

  checkAndCreateWidgetChat(){
    const fields = [];
    const row_field = {"key":"session_cookie","value":this.uid};
    fields.push(row_field);
    this.comService.createNewSessionChat(1,fields, 'he').then(reply=>{
      if (reply.length > 0){
        this.session_id = reply;
        this.getWelcomeScreen();
        //
        //this.setUpSessionOnInit();
      };
    });  
  };

  // set up chat visitor
  setUpSessionVisitorOnInit(){
    this.checkAndCreateCookies();
  }

  

  // check if should show contact details form
  startChat(){    
    if (this.ws_config['cw_cf_force_entry'] === 1  && this.ws_config_form.length > 0){
      this.toggleSideNav(true);
    } else {
      this.goToChatLink(this.session_id);
    }
  }

  getSessionData(){
    this.chatService.getSessionData(this.session_id, 1).then(reply=>{
      if(reply.length>0){
        console.log('session:' + reply);
        this.session = reply[0];
        
      }
    })
  }

  toggleSideNav(event){
    this.userDetailsSideNav.toggle(event);
  }

  

  getChatWelcomeConfiguration(session_id){
    this.chatService.getChatWelcomeConfiguration(session_id).then(reply=>{
        if (reply === undefined){
          this.sessionService.destroy();
          this.checkAndCreateWidgetChat();
          return false;
        }; 

        this.form = reply;

        if (reply['DataTable1'].length > 0){
          // set object from reply data
          this.ws_config = reply['DataTable1'][0];
          this.ws_config_form = reply['DataTable2'];

          // if chat is offline => hide button
          if (this.ws_config.invite_is_oneline == 0){
            this.showConnectBtn = false;
          }
          
          this.loadContactDetailsForm();
          this.checkAndCreateCookies();
          this.chatService.setConfig(reply);
          document.documentElement.style.setProperty('--cm-light-color', this.ws_config["--cm-light-color"]);

          // check if need to show welcome screen
          if (reply['DataTable1'][0]['cw_ws_show'] === 0 && reply['DataTable1'][0]['cw_cf_force_entry'] === 0){
            this.goToChatLink(session_id);
            return false;
          };

          if (reply['DataTable1'][0]['cw_ws_show'] === 0 && reply['DataTable1'][0]['cw_cf_force_entry'] === 1){
            this.startChat();
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 100);
          
        }       
    });
  };

  goToChatLink(session_id){
    //this.sessionService.setSessionId(this.session_id);
    if (this.isWidget){
      this.activeSubScreen.emit('chat');
    } else {
      this.router.navigate(['/link/', session_id]);
    }
  }

  loadContactDetailsForm(){
    if (this.ws_config_form !== undefined){
      const group = {};
      let form_object = this.ws_config_form;

      if (this.ws_config['cw_ws_show'] === 0 && this.ws_config['cw_cf_force_entry'] === 1){
        this.hideBackBtn = false;
      }

      form_object.forEach(control => {
        if ((control.enable_overridden === "0" && control.field_default === "") || (control.enable_overridden === "1")){
          if (control.field_is_required){
            const validators = [ Validators.required ];
            group[control.cf_config_id] = new FormControl(control.field_default, [ Validators.required ]);
          } else {
            group[control.cf_config_id] = new FormControl(control.field_default);
          }

          this.chat_form_object.push(control);
        }
      })
  
      this.form = new FormGroup(group);
    }
  }

  // convert dataFields to array => Json Parse
  convertField(data)
  {
    var array = JSON.parse("[" + data + "]");
    return array;
  }

  // hide form windows (contact details)
  closeSideNav() {
    this.toggleSideNav(false);
  }

  // check form control errors or validation error
  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.form.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
 
  // submit form
  onSubmitForm(){
    if (this.form.valid){
      let formObj = this.form.getRawValue(); // convert form object values to string
      let serializedForm = JSON.stringify(formObj);
      
      // check and create coockies
      this.checkAndCreateCookies();
      let uid = this.sessionService.getUID();
      this.chatService.setChatForm(serializedForm, this.session_id, uid).then(reply => {
        if(reply.length > 0){
          this.findPersonBySession(uid);
        }
      });
      
    }
  }

  // check if cookies exists => if not create one
  checkAndCreateCookies(){
    //this.sessionService.destroy();
    let uid = this.sessionService.getUID();
    console.log(uid + ' UID ');

    // create new session_user in cookie
    if (this.ws_config_form.length > 0) {
      let formObj = this.form.getRawValue(); // convert form object values to string
      let serializedForm = JSON.stringify(formObj);
    }
    
    // check if uid exists in cookies, if not create one 
    if (uid === null || uid === undefined){
      uid = uuid.v4();
      this.sessionService.setUID(uid);
      this.uid = uid;
    } 
  }

  // try to find person by the session data
  findPersonBySession(uid){
    this.chatService.getPersonBySession(this.session_id).then(reply=>{
      if(reply.length > 0){
        if (Number(reply[0]['person_id']) === -1){
          this.updatePerson(reply, uid);
          this.updateSessionParticipant(this.person_id);
        }else{
          this.person_id = reply[0]['person_id'];
          console.log('person id is ' + this.person_id);
          this.person = reply;

          if (this.person_id !== -1 && this.person_id !== undefined){
            this.updateSessionParticipant(this.person_id);
          }
        }
      }
    })
  }

  updatePerson(person, uid){
    let person_obj = JSON.stringify(person);
    this.chatService.setPersonBySession(person_obj, 2, this.session_id, uid, 4).then(reply=>{
      this.person_id = reply[0]['person_id'];
      this.person = reply;
      console.log('update person fn. person id is ' + this.person_id);
    })
  }

  updateSessionParticipant(person_id){
    //this.sessionService.setSessionId(this.session_id);
    if (person_id !== null && person_id !== undefined){
      this.chatService.getPersonData(person_id).then(reply=>{
        if (reply.length > 0){
          this.sessionService.setUserSession(reply);
          this.sessionService.setSessionId(this.session_id);
          this.chatService.sessionParticipantSetGet(-1, this.session_id, person_id, 1, 2).then(reply=> {
            if (reply.length > 0){
              if (this.isWidget){
                this.activeSubScreen.emit('chat');
              } else {
                this.router.navigate(['/link/',this.session_id]);
              }
            }
          })
        }
        
      })
    }
  }

  minimizeChat(){
    this.fabTogglerState.emit('inactive');
  }

  focusInput(id){
    var elmnt = window.document.getElementById(id);
    elmnt.scrollIntoView();
    //window.scrollTo(0, 0); 
  }


}
