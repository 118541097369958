import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { widgetModuleFabAnimations } from './widget-module.animations';
import { WidgetService } from '../widget.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IWidget } from '../../../model/widget.model';
import { SessionService } from 'src/app/services/session.service';
import * as uuid from 'uuid';

@Component({
  selector: 'kt-widget-module',
  templateUrl: './widget-module.component.html',
  styleUrls: ['./widget-module.component.scss'],
  animations: widgetModuleFabAnimations
})
export class WidgetModuleComponent implements OnInit, OnDestroy {
  @Input() public activeSubScreen: string = 'welcome';

  public test = "flaticon-alert";
  public state: string = 'close';
  activeScreen:string = '';
  public ws_config_form = [];
  buttons = [];
  fabTogglerState = 'inactive';
  chatTogglerState = 'inactive';
  public show_widget:boolean = false;
  widget: any = {
    chat_widget_guid: '',
    chat_widget_name: '',
    chat_widget_notes: '',
    widget_type_name: '',
    wid_des_icon: '',
    wid_des_main_color: '',
    wid_des_sub_color: '',
    wid_mar_right: '',
    wid_mar_left: '',
    wid_right: '',
    wid_left: '',
    wid_mar_button: '',
    wid_ser_chat: 0,
    wid_ser_km: 0,
    wid_ser_url: 0,
    wid_srv_survey: 0,
    wid_ser_social: 0,
    wid_srv_form: 0,
    url_1_is_active: 0,
    url_1_icon: '',
    url_1_title: '',
    url_1_url: '',
    url_1_icon_color: '',
    url_1_open_type_id: undefined,
    url_2_is_active: 0,
    url_2_icon: '',
    url_2_title: '',
    url_2_url: '',
    url_2_icon_color: '',
    url_2_open_type_id: undefined,
    url_3_is_active: 0,
    url_3_icon: '',
    url_3_title: '',
    url_3_url: '',
    url_3_icon_color: '',
    url_3_open_type_id: undefined,
    wid_km_node_guid: '',
    wid_chat_invite_guid: '',
    wid_soc_1_is_active: 0,
    wid_soc_1_icon: '',
    wid_soc_1_icon_color: '',
    wid_soc_1_url: '',
    wid_soc_2_is_active: 0,
    wid_soc_2_icon: '',
    wid_soc_2_icon_color: '',
    wid_soc_2_url: '',
    wid_soc_3_is_active: 0,
    wid_soc_3_icon: '',
    wid_soc_3_icon_color: '',
    wid_soc_3_url: '',
    wid_srv_form_guid: '',
    wid_srv_survey_guid: ''
  }


  chat_widget_guid: string='';
  _selectedIcon:string='';

  constructor(private widgetService: WidgetService, private route: ActivatedRoute, private sessionService: SessionService) { }

  ngOnInit(): void {

    var widget_guid = '';
    if (this.route.snapshot.paramMap.get("id") !== null){
      widget_guid = this.route.snapshot.paramMap.get("id");
    }
    this.widget.chat_widget_guid = widget_guid;
    this.getWidgetData();
    this.checkAndCreateCookies();
  }

  getWidgetData(){
    const widget = this.sessionService.getWidget();

    if (widget === undefined || widget === null){
      this.widgetService.getWidgetConfiguration(this.widget, 1).then(reply=>{
        if (reply === null){
          return false;
        }
        if (reply.length > 0){
          this.widget = reply[0];
          this.sessionService.setWidget(JSON.stringify(reply[0]));
          this.show_widget = true;
          document.documentElement.style.setProperty('--wid_win_left', this.widget["wid_win_left"]);
          document.documentElement.style.setProperty('--wid_win_right', this.widget["wid_win_right"]);
        }
      })
    } else {
      this.widget = JSON.parse(widget);
      this.show_widget = true;
      document.documentElement.style.setProperty('--wid_win_left', this.widget["wid_win_left"]);
      document.documentElement.style.setProperty('--wid_win_right', this.widget["wid_win_right"]);
    }
  }

  ngOnDestroy(): void {
    
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons.length = 1;
    window.parent.postMessage('fabToggleActive','*');
  }

  hideItems() {
    this.hideChat();
    this.fabTogglerState = 'inactive';
    this.buttons = [];
    window.parent.postMessage('fabToggleInactive','*');
  }

  showChat() {
    this.chatTogglerState = 'active';
    window.parent.postMessage('chatToggleActive','*');
  }

  hideChat() {
    this.chatTogglerState = 'inactive';
    window.parent.postMessage('chatToggleInactive','*');
  }

  onToggleFab() {
    this.fabTogglerState === 'active' ? this.hideItems() : this.showItems();
    this.activeScreen = 'chat';
  }

  onChatToggle(){
    this.chatTogglerState === 'active' ? this.hideChat() : this.showChat();
    this.activeScreen = '';
  }

  loadExternalUrl(url){
    window.open(url, "_blank");
  }

  // check if cookies exists => if not create one
  checkAndCreateCookies(){
    //this.sessionService.destroy();
    let uid = this.sessionService.getUID();
    
    // check if uid exists in cookies, if not create one 
    if (uid === null || uid === undefined){
      uid = uuid.v4();
      this.sessionService.setUID(uid);
    };

    
  }


}



