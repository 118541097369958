import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "node_modules/ngx-perfect-scrollbar";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LocationService } from "./services/location.service";
import { MainService } from "./services/main.service";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { ChatService } from "./services/chat.service";
import { DeviceDetectorModule } from "ngx-device-detector";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { SplashScreenService } from './services/splash-screen.service';
import { LayoutConfigService } from "./services/layout-config.service";
import { LayoutConfig } from "./config/layout.config";
import { SplashScreenComponent } from "./layout/splash-screen/splash-screen.component";
import { MatProgressSpinnerModule } from "@angular/material";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { SessionService } from "./services/session.service";
import { SocketService } from "./services/socket.service";
import { ChatLinkModule } from "./chat/chat-link/chat-link.module";
import { WidgetModule } from "./chat/widget/widget.module";
import { TrackingPixelComponent } from "./tracking-pixel/tracking-pixel.component";
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    // if (appConfig.getConfig() === null) {
    // 	appConfig.loadConfigs(new LayoutConfig().configs);
    // }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    ErrorPageComponent,
    TrackingPixelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    HttpModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    WidgetModule,
  ],
  providers: [
    LocationService,
    MainService,
    ChatService,
    // SplashScreenService,
    LayoutConfigService,
    SessionService,
    SocketService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      //layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
