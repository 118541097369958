import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ChatAgentAnimation } from "../chat-link-animation";
@Component({
  selector: "app-chat-bot-choices",
  templateUrl: "./chat-bot-choices.component.html",
  styleUrls: ["./chat-bot-choices.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: ChatAgentAnimation,
})
export class ChatBotChoicesComponent implements OnInit {
  @Input() public ws_config: any = {
    cw_ws_footer_btn_color: "",
    cw_ws_footer_btn_text_color: "",
    cw_main_dir: "rtl",
    cw_ws_header_welcome_image: "",
    cw_ws_header_text_color: "",
  };
  @Input() public data: any[] = [];
  @Output() messageEvent = new EventEmitter<string>();
  choices$ = new BehaviorSubject<any[]>([]);
  public message: string = "";
  public config = {
    "avatar-customer-image": "assets/images/default.jpg",
    "--avatar-agent-image2": "5px",
    "--show-avatar": true,
    "--chat-message-text": "Escribe un mensaje",
    "--cm-light-color": "#dfdfdf",
  };
  constructor() {}
  //choices:any[]=[{'text':'איתור פריט'},{'text':'איתור סניף'},{'text':'הזמנת תור לסניף'},{'text':'שיחה עם נציג'}];
  ngOnInit() {
    if (this.data !== undefined) {
      const bot_menu_data = this.data["choices"];
      this.message = this.data["text"];
      this.choices$.next(bot_menu_data);
    }
  }

  sendChoiceMessage(message: string) {
    this.messageEvent.emit(message);
  }
}
