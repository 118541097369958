import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class ErrorService {
  body_error_code: number;
  error_message:string;
  constructor(private router: Router
    ) { }


  public Doctor(error): any{
    
    this.body_error_code = Number(error.status);
    this.error_message = error.statusText;
    switch(this.body_error_code) { 
      // error 400 - bad request
      
      case 400: { 
       // this.toastr.error(this.translate.instant(this.error_message));
         break; 
        }
      case 401:{
       // this.toastr.error(this.translate.instant(this.error_message));
        //this.sessionService.destroy();
        // if (this.router.url.includes("/session/login") !== true){
        //   this.router.navigate(['/session/login/'], { queryParams: { returnUrl: this.router.url }});
        // }
        break;
      }

      case 403:{
       // this.toastr.error(this.translate.instant(this.error_message));
       // this.sessionService.destroy();
        
        // if (this.router.url.includes("/session/login") !== true){
        //   this.router.navigate(['/session/login/'], { queryParams: { returnUrl: this.router.url }});
        // }
        break;
      }
      
      case 405:{
        // this.toastr.error(this.translate.instant(this.error_message));
        // this.sessionService.destroy();
        
        // if (this.router.url.includes("/session/login") !== true){
        //   this.router.navigate(['/session/login/'], { queryParams: { returnUrl: this.router.url }});
        // }
        break;
      }

      case 406: {
        // this.toastr.error(this.translate.instant(this.error_message) ? '' : this.translate.instant(this.error_message));
        // this.sessionService.destroy();
        
        // if (this.router.url.includes("/session/login") !== true){
        //   this.router.navigate(['/session/login/'], { queryParams: { returnUrl: this.router.url }});
        // }
        break;
      }

      
      default: { 
         console.log("Invalid choice"); 
         break;              
      } 

      
   }
    // if (error.status == 404){
    //   this.router.navigate(['404']);
    // }
  }
}
