import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { WidgetRoutingModule } from './widget-routing.module';
import { WidgetModuleComponent } from './widget-module/widget-module.component';
import { MatMenuModule, MatSidenavModule,MatSliderModule, MatButtonToggleModule,  MatExpansionModule, MatIconModule, MatButtonModule, MatDividerModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatListModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatWelcomeComponent } from '../chat-welcome/chat-welcome.component';
import { ChatLinkComponent } from '../chat-link/chat-link.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ChatWelcomeFeedbackComponent } from '../chat-link/chat-welcome-feedback/chat-welcome-feedback.component';
import { ChatBotChoicesComponent } from '../chat-link/chat-bot-choices/chat-bot-choices.component';
@NgModule({
  declarations: [WidgetModuleComponent, ChatWelcomeComponent, ChatLinkComponent, ChatWelcomeFeedbackComponent, ChatBotChoicesComponent],
  imports: [
    CommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    MatMenuModule, 
    MatSidenavModule, 
    MatExpansionModule, 
    MatIconModule, 
    MatListModule,
    MatButtonModule, 
    MatDividerModule, 
    MatSliderModule, 
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    RouterModule.forChild(WidgetRoutingModule)
  ],
})
export class WidgetModule { }
