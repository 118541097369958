import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetModuleComponent} from './widget-module/widget-module.component';


export const WidgetRoutingModule: Routes = [
  {
    path : '',
    component: WidgetModuleComponent
  },
];

