import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ComService {

  constructor(private mainService:MainService, private router: Router) { }

  	createNewSessionWebForm(com_invite_id:number, com_form_id:number, pid:number, session_data_fields:any, language:string): Promise<any[]> {
		let data: any = {
      "com_invite_id":com_invite_id,
      "com_form_id":com_form_id,
      "pid":pid,
      "session_data_fields":session_data_fields,
      "language":language
    	};
		return this.mainService
		.postService('api/Communication/com_ws.svc/form/session/create/', data)
		.then(replay => {
			if (Number(replay['replyErrorId']) < 0){
				//this.router.navigate(['error', '400']);
				//throw("400");
			}else {
				return JSON.parse(replay["ReplyData"]);
			}
		})
		.catch(err => {
			//this.router.navigate(['/error/', '400']);
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}


	createNewSessionChat(com_invite_id:number, session_data_fields:any, language:string):Promise<string>{
		let data: any = {
			"fields":session_data_fields,
			"com_invite_id":com_invite_id,
			"language":language
			};
		return this.mainService
		.postService('api/Communication/com_ws.svc/chat/siteVisitor/', data)
		.then(reply => {
			if (Number(reply['replyErrorId']) < 0){
				//this.router.navigate(['error', '400']);
				//throw("400");
			}else {
				return reply["ReplyData"];
			}
		})
		.catch(err => {
			//this.router.navigate(['/error/', '400']);
			//this.toastr.error(this.translate.instant(err.statusText));
			return err;
		});
	}

	getChatTemplateMessage(session_id:string, template_id:number):Promise<any[]>{
		let data: any = {
			"session_id":session_id,
			"template_id":template_id
			};
		return this.mainService
		.postService('api/Communication/com_ws.svc/com/template/get/', data)
		.then(replay => {
			if (Number(replay['replyErrorId']) < 0){
				//this.router.navigate(['error', '400']);
				//throw("400");
			}else {
				return JSON.parse(replay["ReplyData"]);
			}
		})
		.catch(err => {
			//this.router.navigate(['/error/', '400']);
			//this.toastr.error(this.translate.instant(err.statusText));
			return err;
		});
	}

	getComSatisfaction(session_id:string, com_account_id:number):Promise<any[]>{
		let data: any = {
			"session_id":session_id,
			"com_account_id":com_account_id
			};
		return this.mainService
		.postService('api/Admin/com.svc/admin/com/welcome/feedback/get/', data)
		.then(replay => {
			if (Number(replay['replyErrorId']) < 0){

			}else {
				return JSON.parse(replay["ReplyData"]);
			}
		})
		.catch(err => {
			//this.router.navigate(['/error/', '400']);
			//this.toastr.error(this.translate.instant(err.statusText));
			return err;
		});
	}

	setComSatisfaction(session_id:string, session_sat_score:number, session_sat_note:string):Promise<any[]>{
		let data: any = {
			"session_id":session_id,
			"session_sat_score":session_sat_score,
			"session_sat_note":session_sat_note
			};
		return this.mainService
		.postService('api/Admin/com.svc/admin/com/welcome/feedback/set/', data)
		.then(replay => {
			if (Number(replay['replyErrorId']) < 0){

			}else {
				return JSON.parse(replay["ReplyData"]);
			}
		})
		.catch(err => {
			//this.router.navigate(['/error/', '400']);
			//this.toastr.error(this.translate.instant(err.statusText));
			return err;
		});
	}

	setComTaskSendAnswer(case_event_id_str:string, submitedForm:any):Promise<any[]>{
		let data: any = {
			"case_event_id_str":case_event_id_str,
			"submitedForm":submitedForm
			};
		return this.mainService
		.postService('api/Communication/channels_Ws.svc/task/answer/receive/set/', data)
		.then(replay => {
			if (Number(replay['replyErrorId']) < 0){

			}else {
				return JSON.parse(replay["ReplyData"]);
			}
		})
		.catch(err => {
			//this.router.navigate(['/error/', '400']);
			//this.toastr.error(this.translate.instant(err.statusText));
			return err;
		});
	}
}


