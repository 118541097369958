import { Injectable } from "@angular/core";
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  URLSearchParams,
} from "@angular/http";
// import { SessionService } from '../auth-service/session.service';
import "rxjs/add/operator/toPromise";
import { CONFIG } from "../../assets/config/app.config";
import { ErrorService } from "./error.service";
import { environment } from "../../environments/environment";

@Injectable()
export class MainService {
  headers: Headers;
  options: RequestOptions;
  CONFIG = CONFIG;

  constructor(
    private http: Http,
    // 'Access-Control-Allow-Origin' : 'https://isr.cemax.cloud',
    public errorService: ErrorService
  ) {
    //return this.headers;
  }

  setHeadersForHttpCall() {
    this.headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://pstbnk.cemaxai.com/",
      Accept: "q=0.8;application/json;q=0.9",
      CEMAX_AUTH:
        "APP_ID=" +
        this.CONFIG.APP_ID +
        "," +
        "CUST_ID=" +
        this.CONFIG.CUST_ID +
        "," +
        "UID=" +
        this.CONFIG.UID +
        "," +
        "TOKEN=" +
        this.CONFIG.TOKEN +
        "," +
        "USERNAME=apiUser" +
        "," +
        "TRUNK_ID=" +
        this.CONFIG.TRUNK_ID +
        "," +
        "VER=" +
        this.CONFIG.VER +
        "," +
        "SESSION_ID=" +
        this.CONFIG.SESSION,
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  postService(url: string, param: any): Promise<any> {
    this.setHeadersForHttpCall();
    let body = param;
    this.options = new RequestOptions({ headers: this.headers });

    return this.http
      .post(url, body, this.options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
