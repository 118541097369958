export interface ApplicationConfig {
  defaultLanguage: string;
  direction: string;
  APP_ID: string;
  CUST_ID: string;
  VER: string;
  TRUNK_ID: string;
  HelpDeskInterval: number;
  DefaulPageAfterLogin: string;
  UID: number;
  TOKEN: string;
  SESSION: string;
}

export const CONFIG_DEV: ApplicationConfig = {
  defaultLanguage: "en",
  direction: "rtl",
  APP_ID: "10000010",
  CUST_ID: "DEV",
  VER: "10.03",
  TRUNK_ID: "1000",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "2B2FBB15-855E-4A25-9AA2-FF5C7B405API",
  SESSION: "0v14emdttasvf21xpdf4dsdcubr",
};

export const CONFIG_ISR: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "ltr",
  APP_ID: "10000003",
  CUST_ID: "ISR",
  VER: "10.03",
  TRUNK_ID: "1300",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "2B2FBB15-855E-4A25-9AA2-FF5C7B405570",
  SESSION: "0v14emdffyf21xpdwzxocubr",
};

export const CONFIG_PST: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000020",
  CUST_ID: "PST",
  VER: "10.03",
  TRUNK_ID: "1300",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "5BC34C22-D842-46FD-BDFF-D171C66E0F7E",
  SESSION: "0v20ahdbtashf51jaff7dbhamgt",
};

export const CONFIG_KVM: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000015",
  CUST_ID: "KVM",
  VER: "10.03",
  TRUNK_ID: "1010",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "5C5FAA15-855E-1G21-4DG1-KVMC7B405API",
  SESSION: "5f11emffdasvf21xpdf23fdcu11",
};

export const CONFIG_PSTHD: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "ltr",
  APP_ID: "10000021",
  CUST_ID: "PSTHD",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "3B255B15-155E-4T25-9GD2-TE5C7B115API",
  SESSION: "0v71emfttasvf21xpd5jdsocqvr",
};

export const CONFIG_AAZ: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "ltr",
  APP_ID: "10000025",
  CUST_ID: "AAZ",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "73FE0C10-EF13-4874-8045-07AAZF165152",
  SESSION: "0v71emfttasvf21xpd5jdqqaazqvr",
};

export const CONFIG: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "ltr",
  APP_ID: "10000026",
  CUST_ID: "PSTBNK",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "73FE0C10-EF13-4874-8045-07PSTBNK5152",
  SESSION: "5g71F87jhsflka8qaazqvr",
};

export const CONFIG_PRC: ApplicationConfig = {
  defaultLanguage: "en",
  direction: "ltr",
  APP_ID: "10000026",
  CUST_ID: "PRC",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "3BF94BD3-B681-4396-A291-CE1BPRC29F30",
  SESSION: "5g71F87jhsflka8qaazqvr",
};

export const CONFIG_SNO: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "ltr",
  APP_ID: "10000027",
  CUST_ID: "SNO",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "73FE0C10-EF13-4874-8045-07SNOF165152",
  SESSION: "0v71emfttasvf21xpd5jdqqaazqvr",
};

export const CONFIG_DANT: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "ltr",
  APP_ID: "10000030",
  CUST_ID: "DANT",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "3BF94BD3-B681-4396-A291-CE1BA02DANT29F30",
  SESSION: "0v71emfttasvf21xpd5jdqqaazqvr",
};

export const CONFIG_ISRN: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000030",
  CUST_ID: "ISRN",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "3BF94BD3-B681-4396-A291-CE1BA0229F30",
  SESSION: "0v71emfttasvf21xpd5jdqqaazqvr",
};

export const CONFIG_PST_PRN: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000035",
  CUST_ID: "PST_PRT",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "995912AF-0E32-4620-9E85-87D80C1D1B3A",
  SESSION: "0vdff234nfdf21xdf8723azqvr",
};

export const CONFIG_PST_KMN: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000035",
  CUST_ID: "PST_KMN",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "995912AF-0E32-4620-9E85-87D80C1D1B3A",
  SESSION: "0vdfd5ddf21xdf8723azqvr",
};

export const CONFIG_ZBK: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000037",
  CUST_ID: "ZBK",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "E106D96E-687B-4866-8363-355KLZ4D468",
  SESSION: "0v71emsf987987jdzqvr",
};

export const CONFIG_ZBKTST: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000037",
  CUST_ID: "ZBKTST",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "E106D96E-687B-4866-8363-355KLZ4D468",
  SESSION: "0v71emsf987987jdzqvr",
};

export const CONFIG_CALBNK: ApplicationConfig = {
  defaultLanguage: "en",
  direction: "ltr",
  APP_ID: "10000032",
  CUST_ID: "CALBNK",
  VER: "10.03",
  TRUNK_ID: "3030",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "8D548671-C597-4EEB-8FF8-6ACB30D578DB",
  SESSION: "0v71emfttasvf21xpd5jdqqaazqvr",
};

export const CONFIG_DMO: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000040",
  CUST_ID: "DMO",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "E7F3D7B6-7801-47FC-8798-88A7DMOP02A04",
  SESSION: "0v71emfdsf89742jkqaazqvr",
};

export const CONFIG_RDL: ApplicationConfig = {
  defaultLanguage: "he",
  direction: "rtl",
  APP_ID: "10000041",
  CUST_ID: "RDL",
  VER: "10.03",
  TRUNK_ID: "1301",
  HelpDeskInterval: 30000,
  DefaulPageAfterLogin: "",
  UID: 3,
  TOKEN: "8D548671-C597-4EEB-8FF8-6ACB30D578DB",
  SESSION: "0v71emfttasvf21xpd5jdqqaazqvr",
};
