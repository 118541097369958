import {
    animate,
    keyframes,
    query,
    stagger,
    state,
    style,
    transition,
    trigger
  } from '@angular/animations';
  
  export const ChatAgentAnimation = [
    trigger('fabToggler', [
      state('inactive', style({
        transform: 'rotate(0deg)'
      })),
      state('active', style({
        transform: 'rotateX(180deg)'
        
      })),
      transition('* <=> *', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('speedDialStagger', [
      transition('* => *', [
  
        query(':enter', style({ opacity: 0 }), {optional: true}),
  
        query(':enter', stagger('40ms',
          [
            animate('600ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              keyframes(
                [
                  style({opacity: 0, transform: 'translateY(10px)'}),
                  style({opacity: 1, transform: 'translateY(0)'}),
                ]
              )
            )
          ]
        ), {optional: true}),
  
        query(':leave',
          animate('600ms cubic-bezier(0.4, 0.0, 0.2, 1)',
            keyframes([
              style({opacity: 1, transform: 'translateY(0)'}),
              style({opacity: 0, transform: 'translateY(10px)'}),
            ])
          ), {optional: true}
        )
  
      ])
    ]),
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ];