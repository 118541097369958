import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '../services/chat.service';
import { SessionService } from '../services/session.service';
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit  {
  code = '404';
  return = 'Return back';
  desc = 'Oops! Something went wrong!';
  subtitle: string;
  title: string;
  image: string;
  type = 'error-v6';
  @HostBinding('class') classes = 'kt-grid kt-grid--ver kt-grid--root';
  
  constructor(private route: ActivatedRoute, 
    private chatService: ChatService,
    private sessionService: SessionService) { }

  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get("code");
    var uid = 3
    var user = this.sessionService.getUserSession();
    if (user !== null && user !== undefined )
    {
      uid = this.sessionService.getPersonID();
    }
    switch (this.code){
      // message for not exist session
      case '400':{
        this.chatService.getDictionaryDataById('tb_chat_messages', 6, uid).then(reply=>{
          this.desc = reply[0].dic_translation;
          this.image = 'assets/images/error/bg6.jpg';
          this.title = '🖐';
        });
        break;
      };
      // message for ended session by agent
      case '500':{
        this.chatService.getDictionaryDataById('tb_chat_messages', 7, uid).then(reply=>{
          this.desc = reply[0].dic_translation;
          this.image = 'assets/images/error/bg6.jpg';
          this.title = '🖐';
        });
          
        break;
      }

      case '600':{
        this.chatService.getDictionaryDataById('tb_chat_messages', 7, uid).then(reply=>{
          this.desc = reply[0].dic_translation;
          this.image = 'assets/images/error/bg6.jpg';
          this.title = '🖐';
        });
          
        break;
      }


    }
    
  }
}
