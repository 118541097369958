import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Event } from '../model/event.model';

@Injectable({providedIn: 'root'})
export class ChatService {
//   public chatConfiguration:any[]=[];
  private chatConfiguration = new Subject<any[]>();
  public chatObject:any[]=[];
  constructor(private mainService:MainService, private router: Router) { }

  	getChatWelcomeConfiguration(session_guid:string): Promise<any[]> {
		let data: any = {"session_guid":session_guid};
		return this.mainService
		.postService('api/Communication/com_ws.svc/chat/configuration/get/', data)
		.then(replay => {
			if (Number(replay['replyErrorId']) < 0){
				//this.router.navigate(['error', '400']);
				//throw("400");
			}else {
				this.chatObject = replay["ReplyData"];
				return JSON.parse(replay["ReplyData"]);
			}

		   
		})
		.catch(err => {
			//this.router.navigate(['/error/', '400']);
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}

	setChatForm(form_string: string, session_id: string, uid:string): Promise<any[]>{
		let data: any = {"form":form_string, "session_id": session_id, "uid": uid};
		return this.mainService
		.postService('api/Communication/com_ws.svc/chat/welcome/form/set/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}


	getPersonBySession(session_id:string): Promise<any[]>{
		let data: any = {"session_id": session_id};
		return this.mainService
		.postService('api/Communication/com_ws.svc/session/person/get/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}

	setPersonBySession(person_session:any, action_type_id:number, session_id:string, uid:string, com_account_id:number): Promise<any[]>{
		let data: any = {"person_session": person_session, "action_type_id": action_type_id,  "session_id": session_id, "uid": uid, "com_account_id":com_account_id};
		return this.mainService
		.postService('api/Communication/com_ws.svc/session/person/chat/set/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}

	sessionParticipantSetGet(session_participant_id:number, session_id:string, person_id:number, participant_type_id:number, action_type_id:number): Promise<any[]>{
		let data: any = {"session_participant_id": session_participant_id, "session_id": session_id,  "person_id": person_id, "participant_type_id": participant_type_id, "action_type_id":action_type_id};
		return this.mainService
		.postService('api/Communication/com_ws.svc/session/participant/set/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}

	setConfig(config:any[]){
		this.chatObject = config;
		this.chatConfiguration.next(config);
	}

	getConfig(): Observable<any>{
		return this.chatConfiguration.asObservable();
	}

	getChatObject(){
		return this.chatObject;
	}

	setAttachment(files:any, entity_id:number, entity_type_id:number, session_id:string, person_id:string, case_id:number, com_account_id:number): Promise<any[]>{
		let data: any = {
			"files": files,
		 	"entity_id": entity_id,
			"entity_type_id": entity_type_id,
			"session_id": session_id,
			"person_id": person_id,
			"case_id":case_id,
			"com_account_id": com_account_id
		};

		return this.mainService
		.postService('api/Sys/files_ws.svc/attachment/upload/set/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}

	getPersonData(person_id:number): Promise<any[]>{
		let data: any = {"person_id": person_id};
		return this.mainService
		.postService('api/Person/person.svc/data/get/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}


	getCustomerChatMessages(session_id: string): Promise<any[]>{
		let data: any = {"session_id": session_id};
		return this.mainService
		.postService('api/Communication/com_ws.svc/session/chat/customer/get/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}

	//      Case_Events events, int action_type_id, string session_id, string uid, int com_account_id, int case_id

	transferFromCom(action_type_id: number, session_id:string, pid:number, com_account_id:number, language: string, fields:any, message: string): Promise<any[]>{
		let data: any = {
			"session_id": session_id,
			"pid": pid,
			"com_account_id": com_account_id,
			"language": language,
			"fields": fields,
			"message": message
		};
		return this.mainService
		.postService('api/Communication/com_ws.svc/chat/receive/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}


	setWelcomeBotMenu(): Promise<any[]>{
		let data: any = {};
		return this.mainService
		.postService('api/Communication/com_ws.svc/chat/receive/welcome/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}

	endSession(session_id: string, person_entity_id:number, person_id:number): Promise<any[]>{
		let data: any = {"session_id": session_id, "closed_by_person_entity_id":person_entity_id, "person_id": person_id};
		return this.mainService
		.postService('api/Communication/com_ws.svc/session/close/set/', data)
		.then(replay => {
		   return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		   //this.toastr.error(this.translate.instant(err.statusText));
		   return err;
		});
	}

	getSessionData(session_id:string, action_type_id:number): Promise<any[]>{
		let data: any = {"session_id": session_id, "action_type_id": action_type_id};
		return this.mainService
		.postService('api/Communication/com_ws.svc/session/', data)
		.then(replay => {
			return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		//this.toastr.error(this.translate.instant(err.statusText));
		return err;
		});
	}

	checkIfSessionActive(session_id:string): Promise<any[]>{
		let data: any = {"session_id": session_id};
		return this.mainService
		.postService('api/Communication/com_ws.svc/session/is_active/', data)
		.then(replay => {
			return JSON.parse(replay);
		})
		.catch(err => {
		//this.toastr.error(this.translate.instant(err.statusText));
		return err;
		});
	}

	

	getDictionaryDataById(dic_entity_name:string, key_id:number, uid:number): Promise<any[]>{
		let data: any = {"dic_entity_name": dic_entity_name, "key_id": key_id, "uid": uid};
		return this.mainService
		.postService('api/Common/common_ws.svc/dictionary/key/data/get/', data)
		.then(replay => {
			return JSON.parse(replay["ReplyData"]);
		})
		.catch(err => {
		//this.toastr.error(this.translate.instant(err.statusText));
		return err;
		});
	}
}


