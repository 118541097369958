import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {io} from 'socket.io-client'


@Injectable()
export class SocketService {
  private url = 'https://socSRV10.cemaxai.com';
  private url_old = 'https://chat_srv10.cemax.cloud';
  private socket;

  constructor() { }

  public on(eventName:any, callback:any){
    console.log(`on function ${eventName}`);

    // this.socket.io.on('connect_error', function(err){
    //     console.log('Error connection to server');
    //     callback(err);
    // //   if (err["type"] === "TransportError"){
    // //     this.router.(['/error/', '400']);
    // //   }
    // })

    if (this.socket) {
      this.socket.on(eventName, function (data: any) {
        callback(data);
        
      });
    }
  }

  socketConnect() {
    this.socket = io(this.url, {
      withCredentials: true,
      extraHeaders: {
        "c-header": "eyJhbGciOCJzdHJhdGVneSI6KntaztrYG-tIaUU453dffqPs_dKH436JDAyc"
      }
    });
  }


  

  emit(eventName: any, data: any){
    console.log(`emit function ${eventName}`);
    if(this.socket){
      this.socket.emit(eventName, data);
    }
  }

    disconnect(): void{
     this.socket.disconnect();
   }


  public userConnect(socket_id) {
    console.log( `create user connection for socket_id ${socket_id}`);
    return Observable.create((observer) => {
        this.socket.on('connect', (message) => {
            observer.next(message);
        });
    });
  }

  public sendMessage(message) {
    this.socket.emit('new-message', message);
  }

  public getMessages = () => {
    return Observable.create((observer) => {
        this.socket.on('new-message', (message) => {
            observer.next(message);
        });
    });
  }


}