import { Component, OnInit, ViewEncapsulation, AfterViewChecked, ViewChild, OnDestroy, Output, EventEmitter, Input, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { SocketService } from '../../services/socket.service';
import { ActivatedRoute, Router } from "@angular/router";
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map } from 'rxjs/operators';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../services/session.service';
import { Event } from '../../model/event.model';
import {formatDate} from '@angular/common';
import {ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ComService } from 'src/app/services/com.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChatAgentAnimation } from './chat-link-animation';
import { FormControl } from '@angular/forms';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-chat-link',
  templateUrl: './chat-link.component.html',
  styleUrls: ['./chat-link.component.css'],
  animations: ChatAgentAnimation
})
export class ChatLinkComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChildren('chatAgentScroll') chatAgentScroll: QueryList<PerfectScrollbarComponent>;
  @ViewChild('chatScroll', {static : false})  public directiveScroll: PerfectScrollbarComponent;
  @ViewChild('sidenavFeedback', {static : false}) userFeedbackSideNav;
  @Output() public activeSubScreen: EventEmitter<string> = new EventEmitter();
  @Output() public fabTogglerState: EventEmitter<string> = new EventEmitter();
  @Input() public isWidget: boolean;
  Events$ = new BehaviorSubject<any[]>([]);
  public mode = new FormControl('over');
  state$: Observable<object>;
  public isActive:boolean = false;
  public showBotChoices:boolean = false;
  public ws_config:any = {};
  public botDataMenu:any[]=[];
  public title_message: string = '';
  public welcome_message_show:boolean = true;
  public chat_message_input_text:string = "Type your message here";
  private company_name:string = ''
  private uid: string  = '';
  public config = {
    '--avatar-customer-image':'assets/images/default.jpg',
    '--avatar-agent-image2': '5px',
    '--show-avatar': true,
    '--chat-message-text': 'Escribe un mensaje',
    '--cm-light-color': '#dfdfdf'
  }

  public session_id:string = '';
  public avatar:string = '';
  public avatar_image:string = '';
  sessionDetails:any;
  fileToUpload: File = null;
  // @ViewChild('fileInput') fileInput;
  // @ViewChild('messageText') messageText:ElementRed;
  // @Output() uploadStatus = new EventEmitter();
  files = [];
  scrollHeight = 0;
  scrollHeigthElm = 0;
  Messages: any[]=[];
  message:'';
  customer_information = {
    name:'',
    email:'',
    question:'',
    language:'',
    city:'',
    region:'',
    country:'',
    time_zone:'',
    device:'',
    resolution:'',
    operation_system:'',
    browser:'',
    javascript:'',
    ip_address:'',
    host:'',
    isp:'',
    user_id:'',
  };

  connection;
  private typing_timeout: any;
  public typing:boolean = false;
  public agent_name:string = '';
  private userSession:any;
  private session_state_id:any;

  constructor(private locationService: LocationService,
    public translate: TranslateService,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private chatService: ChatService,
    private router: Router,
    private sessionService: SessionService,
    private cd: ChangeDetectorRef,
    private comService: ComService
    ) { }

  ngOnInit() {
    // get session_id from url
    this.session_id = this.sessionService.getSessionId();
    this.userSession = this.sessionService.getUserSession();
    this.uid = this.sessionService.getUID();
    

    if (this.session_id === null || this.session_id === undefined || this.session_id === '' || this.session_id === 'undefined' || this.session_id === 'null'){
      if (this.isWidget){
        this.activeSubScreen.emit('welcome');
        return false;
      } else {
        this.router.navigate(['welcome', '']);
        return false;
      }
    } else {
      this.chatService.checkIfSessionActive(this.session_id).then(reply=>{
        this.session_state_id = reply;
        if (reply.toString() === '4'){
          if (this.isWidget){
            this.activeSubScreen.emit('welcome');
            return false;
          } else {
            this.router.navigate(['welcome', '']);
            return false;
          }
        }

        if (reply.toString() === '1'){
          this.sendFirstMessage();
        }
      })
    }

    // check if user session exists
    if (this.userSession === null || this.userSession === undefined || this.uid === null || this.uid === undefined || this.uid === 'null'){
      this.findPersonBySession(this.session_id);
    } else {
      this.avatar = this.userSession[0]['person_image_url'];
    }

    this.getChatConfiguration();
    this.getCustomerInformation();
    this.socketConnect();
  }


  ngOnDestroy(){

  }



  // try to find person by the session data
  findPersonBySession(uid){
    this.chatService.getPersonBySession(this.session_id).then(reply=>{
      if(reply.length > 0){
        var person_id = reply[0]['person_id'];
        
        if (person_id !== "-1"){
          this.sessionService.setUserSession(reply);
          this.userSession = reply;
          let uid = reply[0]['session_cookie'];
          this.sessionService.setUID(uid);
          this.avatar = this.userSession[0]['person_image_url'];
        }
      }
    })
  }


  // get all chat messages
  getChatHistory(){
    
    this.chatService.getCustomerChatMessages(this.session_id).then(reply=>{
      if (reply.length>0){
        this.Events$.next(reply);
        
        // events.forEach(event => {
        //   var image_url:any = "";
        //   if (event.file_type !== null){
        //     image_url = this.getImageUrlForMessage(event.file_type, event.file_base64);
        //   }

        //   if (event.person_image_url.includes('base64')){
        //     person_image = event.person_image_url;
        //   } else {
        //     person_image = event.person_image_url;
        //   }

        //   let message = {
        //     'message_id': event.case_event_id, 
        //     'message_time': event.event_create_date, 
        //     'name': event.display_name, 
        //     'message': event.case_notes, 
        //     'url': event.file_base64, 
        //     'image_url': image_url,
        //     'from_agent': event.is_agent, 
        //     'avatar': person_image,
        //     'event_meta_data': ''
        //   };
        //   this.Messages.push(message);
        // });
      }
    })
  }

  // get chat configuration
  getChatConfiguration(){
    const config = this.chatService.getChatObject();

    if (config.length === 0 || config === undefined){
      this.chatService.getChatWelcomeConfiguration(this.session_id).then(reply=>{
        if (reply === undefined){
          //this.router.navigate(['error', '400']);
          return false;
        };

        if (reply['DataTable1'].length > 0){   
          // set object from reply data
          this.ws_config = reply['DataTable1'][0];
          
          // check if chat offline 
          if (this.ws_config.invite_is_oneline == 0){
            this.router.navigate(['welcome', this.session_id]);
            return false;
          }

          this.chatService.setConfig(reply);
          this.generateChatObjectUI();
          //this.pushWelcomeMessage(this.ws_config['cw_ws_footer_welcome_msg']);
        };       
      });
    } else {
      this.ws_config = config['DataTable1'][0];
      this.generateChatObjectUI();
      //this.pushWelcomeMessage(this.ws_config['cw_ws_footer_welcome_msg']);
    }
    
  }

  generateChatObjectUI(){
    document.documentElement.style.setProperty('--cm-light-color', this.ws_config["cw_msg_text_background"]);
    document.documentElement.style.setProperty('--cm-icon-color', this.ws_config["cw_ws_header_background_color"]);
    document.documentElement.style.setProperty('--devider-color', this.ws_config["cw_msg_text_background"]);
    document.documentElement.style.setProperty('--cm-text-color', this.ws_config["cw_ws_header_text_color"]);
  }

  // get customer information
  getCustomerInformation(){
    //this.customer_information.time_zone = this.getGMT();
    let device = this.deviceService.getDeviceInfo();
    this.customer_information.operation_system = device['os'] + '(' + device['os_version'] + ')';
    this.customer_information.browser = device['browser'] + '(' + device['browser_version'] + ')';

    if (this.deviceService.isDesktop()) { this.customer_information.device = 'Desktop';};
    if (this.deviceService.isMobile()) { this.customer_information.device = 'Mobile';};
    if (this.deviceService.isTablet()) { this.customer_information.device = 'Tablet';};

    this.customer_information.resolution = window.innerWidth + 'x' + window.innerHeight;


    this.customer_information.language = navigator.language;

  }

  getGMT(){
    var rightNow = new Date();
    var jan1 = new Date(rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);
    var temp = jan1.toUTCString();
    return temp;
  }


  // get message incoming audio
  playMessageAudio(){
      let audio = new Audio();
      audio.muted = false;
      audio.src = "../../../assets/audio/chat_2016.mp3";
      audio.load();
      audio.play();
  }

  //push welcome message 
  // pushWelcomeMessage(message){
  //   if (this.welcome_message_show==true){
  //     var time = new Date();
  //     let welcome_message = {'case_event_id': 1, 'event_create_date': time, 'display_name': this.company_name, 'case_notes': message, url:'', 'is_agent': true, 'person_image_url':this.ws_config['cw_ws_footer_welcome_image'], 'event_meta_data': ''};
  //     this.Events$.next(this.Events$.getValue().concat(welcome_message));
  //   }
  // }

  //push goodbye message
  pushGoodbyeMessage(){
    var time = new Date();
      this.comService.getChatTemplateMessage(this.session_id, 1).then(reply=>{
        if (reply.length > 0){
          let message = reply[0].dic_translation;
          let welcome_message = {'case_event_id': 1, 'event_create_date': time, 'display_name': this.company_name, 'case_notes': message, url:'', 'is_agent': true, 'person_image_url':this.ws_config['cw_ws_footer_welcome_image'], 'event_meta_data': ''};
          this.Events$.next(this.Events$.getValue().concat(welcome_message));
        }
      })      
  }

  // check what key pressed in the message input
  InputKeyUp(event){
    if (event !== null && event.keyCode == 13){
      if (!event.ctrlKey && !event.shiftKey){
        this.sendMessage();  
        this.defintChatUiHeight(50);
      }
    } else {
      this.announceTyping(true);
    }
  }

  // update ui when typing text
  updateChatUI(event){
    var height=parseInt(document.getElementById('cm_chat_text').style.height.replace('px','')),elem = document.getElementById('cm_chat_text');
    if(isNaN(height))
        height=50;

    if (elem.clientHeight < elem.scrollHeight)
    {
    
        height += 22;
        elem.style.height = height + 'px';

    }
    else if(document.getElementById('cm_chat_text')['value'] == '')
    {
        height = 50;
        elem.style.height = height + 'px';
    }

    this.defintChatUiHeight(height);
  }


  defintChatUiHeight(height){
    if (this.ws_config['cw_main_dir'] === 'ltr'){
      document.getElementById('cm_chat_text_send').style.display = (document.getElementById('cm_chat_text')['value'].length > 0) ? 'block' : 'none'; // if text length is bigger than 0
      //document.getElementById('cm_chat_text_file').style.right = this.config['--show-camera'] ? '50px' : '10px'; //change position of attachment icon if camera icon is hidden
      document.getElementById('cm_chat_text_send').style.right =  this.config['--show-camera'] ? '70px' : '50px'; //change position of sent icon if camera icon is hidden
      // document.getElementById('cm_chat_text_file').style.display =  "block";
      document.getElementById('cm_chat_text_sc').style.display = this.config['--show-camera'] ? 'block' : 'none';
      //document.getElementById("cm_chat_content_box").style.bottom = (0+height+'px');
      document.getElementById('cm_chat_overlay_bottom').style.height = height+'px';
    } else {
      document.getElementById('cm_chat_text_send').style.display = (document.getElementById('cm_chat_text')['value'].length > 0) ? 'block' : 'none'; // if text length is bigger than 0
      //document.getElementById('cm_chat_text_file').style.left = this.config['--show-camera'] ? '50px' : '10px'; //change position of attachment icon if camera icon is hidden
      document.getElementById('cm_chat_text_send').style.left =  this.config['--show-camera'] ? '70px' : '50px'; //change position of sent icon if camera icon is hidden
      //document.getElementById('cm_chat_text_file').style.display =  "block";
      document.getElementById('cm_chat_text_sc').style.display = this.config['--show-camera'] ? 'block' : 'none';
      //document.getElementById("cm_chat_content_box").style.bottom = (0+height+'px');
      document.getElementById('cm_chat_overlay_bottom').style.height = height+'px';
    }
  }

  cm_chat_set_focus(){

  }


  
  socketConnect(){
        this.socketService.socketConnect();
        
        this.socketService.on('reconnect', (attemptNumber)=>{
          this.title_message = 'Session Reconnected...';
          this.socketService.emit('room', this.session_id);
          this.getChatHistory();
        });

        this.socketService.on("connect", (data)=>{
          this.setSocketStatus('Online');
          this.socketService.emit('room', this.session_id);
          this.getChatHistory();
          this.isActive = true;
        });
    
        this.socketService.on("disconnect", (data)=> {
          this.setSocketStatus('Offline');
          this.isActive = false;
        });
        
        this.socketService.on('error', function (err) {
          console.log('error');
        });

        this.socketService.on('connect_timeout', function (err) {
          console.log('connect_timeout');
        });

    
      this.socketService.on("connect_failed",  ()=>{
        console.log('connect_failed');
      })

      this.socketService.on("connect_error", (data)=>{
        console.log('connect_error');
        setTimeout(() => {
          this.setSocketStatus('Session Connect Error...');
          this.socketService.socketConnect();
          this.isActive = false;
        }, 1000);
      });

        this.socketService.on('message-received', (data)=>{
          this.typing = false;
          var from_agent = data.from_agent;

          var time = new Date();

          // hide file loading
          document.getElementById('cm_chat_bot_reply_loading').style.display = 'none';
          
          
          
          if (from_agent){
            this.playMessageAudio();
            let welcome_message = {'case_event_id': 1, 'event_create_date': time, 'display_name': data.from, 'case_notes': data.content, 'url': data.url, 'image_url': data.image_url, 'is_agent':from_agent, 'person_image_url':  data.avatar, 'event_meta_data': ''};
            this.Events$.next(this.Events$.getValue().concat(welcome_message));
          }
          
          setTimeout(() => {
            this.scrollToBottom();
          } ,20);

        });

        // announce user is typing
          this.socketService.on('agent-typing', (data)=>{
          this.typing = true;
          this.agent_name = data.from;
          this.avatar_image = data.avatar;
        })

        // announce user stop typing
        this.socketService.on('agent-untyping', (data)=>{
          this.typing = false;
        })   
        
        // agent end session
        this.socketService.on('agent-end-session', (data)=>{
          this.socketDisconnectByAgent();
        })   

        this.socketService.on('openFeedback', (data) => {
          this.openFeedback();
        });

        this.socketService.on('showBotMenu', (data) => {
          this.botDataMenu = JSON.parse(data.data);
          this.showHideBotMenu(true);
        })
  }


  setSocketStatus(status){
    this.title_message = status;
  }

  socketDisconnectByCutsomer(){
    let userSession = this.sessionService.getUserSession();
    this.pushGoodbyeMessage();    
    document.getElementById("cm_chat_overlay_bottom").style.display = 'none';
    document.getElementById("cm_chat_content_box").style.bottom = '0px';
    document.getElementById("cm_chat_overlay_close").style.display = 'none';
    // document.getElementById("cm_chat_options_button").style.display = 'none';
    this.closeChatMenu();
    this.socketService.disconnect();

    
    // customer end session
    this.chatService.endSession(this.session_id, 2, userSession[0]["person_id"]).then(reply=>{
      this.openFeedback();

      this.sessionService.destroy();
    });
  }

  socketDisconnectByAgent(){
    document.getElementById("cm_chat_overlay_bottom").style.display = 'none';
    document.getElementById("cm_chat_content_box").style.bottom = '0px';
    document.getElementById("cm_chat_overlay_close").style.display = 'none';
    //document.getElementById("cm_chat_overlay_minimize").style.display = 'none';
    //document.getElementById("cm_chat_options_button").style.display = 'none';
    this.closeChatMenu();
    //this.pushGoodbyeMessage();
    this.socketService.disconnect();
    this.openFeedback();
    this.sessionService.destroy();

    
  }


  sendButtonMessage(message){
    this.message = message;
    this.sendMessage();
    this.showHideBotMenu(false);
  }

  sendFirstMessage(){
    this.chatService.setWelcomeBotMenu().then(reply=>{
      if (reply.length>0){
        console.log(reply);
        const bot_menu_data = JSON.parse(reply[0].server_config_value)["response"][0];
        const bot_menu_message = bot_menu_data["wrapped"].text;
        
        this.botDataMenu = bot_menu_data;
        this.showHideBotMenu(true);
      }
    })
  }
  

  sendMessage(){
    // check message length
     if (this.message.length === 0){
       return false;
     }

     this.showHideBotMenu(false);

    let userSession = this.sessionService.getUserSession();
    let send_message = this.message;
    this.insertEventToEventsObj(this.message);
    this.chatService.transferFromCom(2, this.session_id, userSession[0]["person_id"], 4, userSession[0]["language_full_code"], "", send_message).then(reply=>{
      if (reply.length > 0){
        let event_id = reply[0]["case_event_id"];
        if (event_id > 0){
          // this.socketService.emit('new-message', {from: userSession[0]["person_display_name"],content: send_message, url: '', room: this.session_id, dir:'out', size:'left', from_agent:false, avatar:this.avatar});
          this.message = '';
          document.getElementById('cm_chat_text_send').style.display = 'none';
        }
      }
    })
    
  }

  listenToIncomingMessages(){
    
  }


  announceTyping(_typing){
    //this.typing = false;
    this.socketService.emit('customer-typing', { from: this.userSession[0]["person_display_name"], content: '', room: this.session_id, dir: 'out', size: 'left', avatar: this.avatar });
    clearTimeout(this.typing_timeout);
    this.typing_timeout = setTimeout(() => { this.announceNotTyping(); }, 2000);
  }

  announceNotTyping(){
  //this.typing = false;
    this.socketService.emit('customer-untyping',{ from: this.userSession[0]["person_display_name"], content: '', room: this.session_id, dir: 'out', size: 'left', avatar: this.avatar });
  }


  ngAfterViewChecked(){
    if(this.directiveScroll){
       this.directiveScroll.directiveRef.scrollToBottom(0,0);
    } 
  }

 // open file browser upload
 InitFileUpload(){
  document.getElementById('cm_overlay_file_upload').addEventListener('change', this.UploadFile, false);

  setTimeout(function(){
    document.getElementById('cm_overlay_file_upload').click();
  },400);
 }

 // upload files to browser
 UploadFile = function(file){
 
 }

 scrollToBottom(): void {
  try {
      if (this.chatAgentScroll) {
          this.chatAgentScroll.first.directiveRef.scrollToBottom(0, 0);
      }
  } catch (err) {
      console.log('Erro scrollToBottom');
  }
}

 generateFileUpload(filename, filetype, filebase64, fileSize){
  document.getElementById('cm_chat_bot_reply_loading').style.display = 'inline';
  
  let file_name = filename;
  let file_type = filetype;
  let file_base64 = filebase64;
  let file_size = fileSize;
  return{
      file_name: file_name,
      file_type: file_type,
      file_base64: file_base64,
      file_size: file_size
  }
}

fileProgress(fileInput: any) {
  this.files=[];
  var fileData = <File>fileInput.target.files[0];
  var reader = new FileReader();  
  reader.readAsDataURL(fileData); 
  reader.onload = (_event) => { 
    this.files.push(this.generateFileUpload(fileData.name, fileData.type, (reader.result as string).split(',')[1], fileData.size));
    this.sendAttachment();
  }
}


 // send attachment to the server
  sendAttachment(){
    let userSession = this.sessionService.getUserSession();
    const entity_id = 1; // case_event_id
    const com_account_id = 4; // account provider
    this.chatService.setAttachment(this.files, entity_id, com_account_id, this.session_id, userSession[0]["person_id"], -1, 4).then(reply=>{
     // if (reply.length > 0){
        let url:string = 'data:' + this.files[0].file_type + ';base64,' + this.files[0].file_base64;
        


        //this.socketService.emit('new-message', {from: userSession[0]["person_display_name"], content: this.files[0].file_name, url:url, image_url: this.getImageUrlForMessage(this.files[0].file_type, url), room: this.session_id, dir:'out', size:'left', from_agent:false, avatar:this.avatar});
    //  }
    });
  }

  getImageUrlForMessage(fileType:string, url:string):string{
    switch (fileType){
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "assets/images/files_icons/word.png";
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "assets/images/files_icons/excel.png";
        break;

      case "application/pdf":
        return "assets/images/files_icons/pdf.png";
        break;

        case "image/jpeg":
        return url;
        break;

      case "image/png":
        return url;
        break;
    }
  }

  debugBase64(base64URL){
    var win;
    win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    window.open()
  }

  base64_url_encode(_text){
	if(_text.length == 0)
		return "";

	_text = this.base64_encode(_text.toString());
	_text = _text.replace(/=/g, "_");
	_text = _text.replace(/\+/g, "-");
	_text = _text.replace(/\//g, ",");
	return _text;
  }

  base64_encode(_input){
    var base64_chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    _input = this.utf8_encode(_input);
    while (i < _input.length) 
    {
      chr1 = _input.charCodeAt(i++);
      chr2 = _input.charCodeAt(i++);
      chr3 = _input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if(isNaN(chr2)) 
      {
        enc3 = enc4 = 64;
      } 
      else if(isNaN(chr3)) 
      {
        enc4 = 64;
      }
      output = output + base64_chars.charAt(enc1) + base64_chars.charAt(enc2) +	base64_chars.charAt(enc3) + base64_chars.charAt(enc4);
    }
    return output;
  }

  utf8_encode(_string){
    _string = _string.replace(/\r\n/g,"\n");
    var enc = "";
    for (var n = 0; n < _string.length; n++) 
    {
      var c = _string.charCodeAt(n);
      if (c < 128) 
      {
        enc += String.fromCharCode(c);
      }
      else if((c > 127) && (c < 2048))
      {
        enc += String.fromCharCode((c >> 6) | 192);
        enc += String.fromCharCode((c & 63) | 128);
      }
      else 
      {
        enc += String.fromCharCode((c >> 12) | 224);
        enc += String.fromCharCode(((c >> 6) & 63) | 128);
        enc += String.fromCharCode((c & 63) | 128);
      }
    }
    return enc;
  }

  openChatMenu(){
    document.getElementById('cm_chat_options_table').style.display = (document.getElementById('cm_chat_options_table').style.display=='none') ? '' : 'none';
    document.getElementById("cm_chat_overlay_bottom").style.opacity = (document.getElementById('cm_chat_options_table').style.display=='none') ? '0.8' : '0.1';
    document.getElementById("cm_chat_content_box").style.opacity = (document.getElementById('cm_chat_options_table').style.display!='none') ? '.2' : '1';
  }

  closeChatMenu(){
    document.getElementById('cm_chat_options_table').style.display = 'none';
    document.getElementById("cm_chat_overlay_bottom").style.opacity = '0.8';
    document.getElementById("cm_chat_content_box").style.opacity = '1';
  }

  

  toMSJSON(selected_date) {
    return "\/Date(" + selected_date.getTime()+selected_date.getTimezoneOffset() + ")\/";
  };

  insertEventToEventsObj(message){
    let time = new Date;    
    let event_message = {'case_event_id': 1, 'event_create_date': time, 'display_name': this.userSession[0]["person_display_name"], 'case_notes': message, 'url': "", 'image_url': "", 'is_agent':false, 'person_image_url': this.userSession[0]["person_image_url"], 'event_meta_data':''};
    this.Events$.next(this.Events$.getValue().concat(event_message));
    this.message = '';

    setTimeout(() => {
      this.scrollToBottom();
    } ,20)
  }

  minimizeChat(){
    this.fabTogglerState.emit('inactive');
  }

  openFeedback(){
    this.toggleFeedback(true);
  }

  toggleFeedback(event){
    this.userFeedbackSideNav.toggle(event);
  }

  focusInput(id){
    var elmnt = window.document.getElementById(id);
    elmnt.scrollIntoView();
    //window.scrollTo(0, 0); 
  }

 scrollTo(_index: any) {
    if (window.screen.width === 360) {
        var height = document.getElementById(_index).offsetTop;
        if (height > 0) { this.scrollHeight = height; }
        else if (this.scrollHeigthElm > 0) { this.scrollHeight = 0; }
    }
} 

setScrollHeigth(_event: any) { 
  this.scrollHeigthElm = _event.srcElement.scrollTop; 
}

showHideBotMenu(status){
  this.showBotChoices = status;
}



}
