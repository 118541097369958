import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ComService } from "src/app/services/com.service";
import { widgetModuleFabAnimations } from "../../widget/widget-module/widget-module.animations";

@Component({
  selector: "app-chat-welcome-feedback",
  templateUrl: "./chat-welcome-feedback.component.html",
  styleUrls: ["./chat-welcome-feedback.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChatWelcomeFeedbackComponent implements OnInit {
  @Input() public session_id: string;
  @Input() public ws_config: string;
  @Output() public feedbackTogglerState: EventEmitter<boolean> =
    new EventEmitter();
  public com_feedback_cnf: any = {
    com_sat_subject: "",
    com_sat_body: "",
    com_sat_notes_body: "",
  };
  satOptions: any;
  feedBackValue: number = 0;
  mySelectedTabIndex: any;
  feedbackNotes: string = "";
  feedbackSent: boolean = false;
  constructor(
    private comService: ComService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.translate.setDefaultLang("en");
    if (
      this.session_id === null ||
      this.session_id === undefined ||
      this.session_id === "" ||
      this.session_id === "undefined" ||
      this.session_id === "null"
    ) {
      return false;
    } else {
      this.comService.getComSatisfaction(this.session_id, 4).then((reply) => {
        if (reply.length > 0) {
          this.com_feedback_cnf = reply[0];
          var option = this.convertField(reply[0].com_sat_options);
          this.satOptions = this.generateOptionData(option);
        }
      });
    }
  }

  // convert dataFields to array => Json Parse
  convertField(data) {
    var array = JSON.parse("[" + data + "]");
    return array;
  }

  generateOptionData(satOptions) {
    let arr = [];

    for (let i = 0; i < satOptions.length; i++) {
      let data = {
        id: i + 1,
        name: satOptions[i],
      };
      arr.push(data);
    }

    return arr;
  }

  closeSideNav() {
    this.feedbackTogglerState.emit(false);
  }

  selectionChanged(event) {}

  sendFeedback() {
    this.comService
      .setComSatisfaction(
        this.session_id,
        this.mySelectedTabIndex,
        this.feedbackNotes
      )
      .then((reply) => {
        if (reply.length > 0) {
          this.feedbackSent = true;
        }
      });
  }
}
