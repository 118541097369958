import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {
    _userSession = JSON.parse(localStorage.getItem('session.userSession'));
    _blockSession = JSON.parse(localStorage.getItem('session.blockSession'));
    _session_id = localStorage.getItem('session.session_id');
    _token_id = localStorage.getItem('session.token');
    _UID = localStorage.getItem('session.UID');
    _widgetSession = sessionStorage.getItem('session.widget');
    _welcomeLayout = sessionStorage.getItem('session.welcomeLayout');
  constructor() { }


  getUserSession(){
    return this._userSession;
  }

  getBlock() {
    return this._blockSession;
  };

  getUID(){
    return this._UID;
  }


  getSessionId(){
    return this._session_id;
  };
  
  getTokenId(){
    return this._token_id;
  };

  getSystemAlign(){
    return this._userSession["language_dir"];
  }

  getPersonID(){
    return this._userSession["person_Id"]
  }

  getWidget(){
    return this._widgetSession;
  }

  getWelcomeLayout(){
    return this._welcomeLayout;
  }

  setUID(uid){
    this._UID = uid;
    localStorage.setItem('session.UID', uid);
  }
   
  setUserSession(user_session){
      this._userSession = user_session;
      localStorage.setItem('session.userSession', JSON.stringify(user_session));
  }

  setBlockSession(block_session) {
    this._blockSession = block_session;
    localStorage.setItem('session.blockSession', JSON.stringify(block_session));
    return this;
  };


  setSessionId(session_id){
    this._session_id = session_id;
    localStorage.setItem('session.session_id',session_id);
    return this;
  }

  setTokenId(token_id){
    this._token_id = token_id;
    localStorage.setItem('session.token', token_id);
    return this;
  }

  setWidget(widget){
    this._widgetSession = widget;
    sessionStorage.setItem('session.widget', widget);
  }

  setWelcomeLayout(welcome){
    this._welcomeLayout = welcome;
    sessionStorage.setItem('session.welcomeLayout', welcome);
  }

 
  destroy() {
     //this.setUserSession(null);
     this.setSessionId(null);
     //this.setUserLanguage(null)
     return true;
   };

}
