import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ChatLinkModule } from "./chat/chat-link/chat-link.module";

import { ErrorPageComponent } from "./error-page/error-page.component";
import { ChatWelcomeComponent } from "./chat/chat-welcome/chat-welcome.component";
import { ChatLinkComponent } from "./chat/chat-link/chat-link.component";
import { TrackingPixelComponent } from "./tracking-pixel/tracking-pixel.component";

const routes: Routes = [
  //  {path: 'welcome/:session_id', loadChildren: () => import('./chat/chat-welcome/chat-welcome.module').then(m => m.ChatWelcomeModule)},
  //  {path: 'welcome', loadChildren: () => import('./chat/chat-welcome/chat-welcome.module').then(m => m.ChatWelcomeModule)},
  //  {path: 'link/:session_id', loadChildren: () => import('./chat/chat-link/chat-link.module').then(m => m.ChatLinkModule)},

  { path: "error/:code", component: ErrorPageComponent },
  { path: "welcome/:session_id", component: ChatWelcomeComponent },
  { path: "welcome", component: ChatWelcomeComponent },
  { path: "link/:session_id", component: ChatLinkComponent },
  { path: "cmxpixtrk", component: TrackingPixelComponent },
  { path: "cmxpixtrk/:id", component: TrackingPixelComponent },
  // {path: '', redirectTo: 'welcome', pathMatch: 'full'},
  // {path: '**', redirectTo: 'welcome', pathMatch: 'full'},
  {
    path: "widget/:id",
    loadChildren: () =>
      import("./chat/widget/widget.module").then((m) => m.WidgetModule),
  },
  {
    path: "form/:id",
    loadChildren: () => import("./form/form.module").then((m) => m.FormModule),
  },
  {
    path: "form/:id/:lng",
    loadChildren: () => import("./form/form.module").then((m) => m.FormModule),
  },
  {
    path: "task/:case_event_id",
    loadChildren: () => import("./task/task.module").then((m) => m.TaskModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
