import { Component, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { trigger, transition, animate, style, keyframes, state } from '@angular/animations';
import { TranslateService} from '@ngx-translate/core';
import { CONFIG } from '../assets/config/app.config';
import { Subscription } from 'rxjs';
import { TranslationService } from './services/translation.service'
//import { SplashScreenService } from './services/splash-screen.service'
import { LayoutConfigService } from './services/layout-config.service'
import { NavigationEnd, Router } from '@angular/router';
import { ChatService } from './services/chat.service';
import { SocketService } from './services/socket.service';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('lz_anim_flip', [
      state('flipped', style({
        transform: 'rotateY(0deg)'
      })),
      state('unflipped', style({
        transform: 'transform:rotateX(100)'
      })),
    ])
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'CEMax V10 OC';
  public windowOpen = false;
  flip = 'unflipped';
  layout                : any = CONFIG.direction;
  currentLang           : any = CONFIG.defaultLanguage;
  
  private unsubscribe   : Subscription[] = [];
  subscription: Subscription;
  message: any;
  constructor(
    translate: TranslateService,
    private translationService: TranslationService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    //private splashScreenService: SplashScreenService,
    private chatService: ChatService,
    //private socketService: SocketService
    ){
    // set active languages
    translate.addLangs(['en', 'he', 'zh', 'es', 'ru']);

    // set default language from config file
    translate.setDefaultLang(CONFIG.defaultLanguage);

    // get current language from config file
    this.currentLang = CONFIG.defaultLanguage;

    // get browser language
    const browserLang: string = translate.getBrowserLang();

    // check if current language is empty => get browser language;
    if (this.currentLang !== ''){
      translate.use(this.currentLang);
    } else {
        translate.use(browserLang.match(/en|he/) ? browserLang : 'he');
        this.currentLang = browserLang.match(/en|he/) ? browserLang : 'he';
    }

    // this.subscription = this.chatService.getConfig().subscribe(message => { 
    // this.message = message; 

    //   if (this.message['DataTable1'].length > 0){
    //     this.layout = this.message['DataTable1'][0]['cw_main_dir'];
    //     let chat_language_code_name = this.message['DataTable1'][0]['cw_language_code_text'];

    //     if (chat_language_code_name !== '' && chat_language_code_name !== null){
    //       if (chat_language_code_name === 'en-us'){
    //         chat_language_code_name = 'en';
    //       }
    //       translate.setDefaultLang(chat_language_code_name);
    //       translate.use(chat_language_code_name);
    //     }
    //   }
    // });
  }


  ngOnInit(): void {
		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				//this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
  }
  

  changeRTL(isChecked) {
		if(isChecked){
			this.layout = "rtl"
		}  
		else {
			this.layout = "ltr"
		}
	}

  // openChatWindow(event){
  //   this.flip = (this.flip === 'unflipped') ? 'flipped' : 'unflipped';
  // }


  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
    this.subscription.unsubscribe();
	}
}
